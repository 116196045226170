export default [
  {
    title: 'Shared Profiles',
    route: 'shared-profiles',
    icon: 'HomeIcon',
  },
  {
    title: 'Calendar',
    route: 'calendar',
    icon: 'CalendarIcon',
  },
  // {
  //   title: 'Faq',
  //   route: 'faq',
  //   icon: 'HelpCircleIcon',
  // },
]
